
import React, { useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Comments } from '@hyvor/hyvor-talk-react';
import SubscribeButton from '../../../Components/SubscribeButton';
import ResponsiveYoutube from '../../../Components/ResponsiveYoutube';
import DropCap1 from '../../Travel/DropCap1';
import ArticleTitle from '../../../Components/ArticleTitle';
import SocialShare from '../../../Home/SocialShare';
import ResponsiveIframe from '../../../Components/ResponsiveIframe';
import NextArticle from '../../../Components/NextArticleComponent';

const pinterest = `${process.env.PUBLIC_URL}/Images/Articles/pinterest.png`;
const dccImage = `${process.env.PUBLIC_URL}/Images/Articles/DCC/DCCMakeupThumb.jpg`;
const signature = `${process.env.PUBLIC_URL}/Images/Articles/Signature.png`;

const Card = ({ blogContent }) => (
  <div
    style={{
      margin: '0 auto',
      maxWidth: '95%', // Control max width to center the content
      padding: '10px', // Add padding to prevent text from touching the edges
    }}
  >
    <div className="card">
      <div className="blog-content">{blogContent}</div>
    </div>
  </div>
);

const DCCMakeupTutorial = () => {
  const blogRef = useRef(null);

  useEffect(() => {
    const loadGoogleAnalytics = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-RT6GR7JXYG';
      document.head.appendChild(script);

      const inlineScript = document.createElement('script');
      inlineScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-RT6GR7JXYG');
      `;
      document.head.appendChild(inlineScript);
    };

    const loadGoogleAds = () => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4660168246825318';
      script.crossOrigin = 'anonymous';
      document.head.appendChild(script);
    };

    loadGoogleAnalytics();
    loadGoogleAds();
  }, []);

  const websiteId = '10910';

  const headingStyle = {
    textAlign: 'center',
    fontSize: '2.5rem',
    fontWeight: '400',
    fontFamily: "Playfair Display, serif",
    color: '#000000',
    maxWidth: '95%',
    margin: '30px auto',
  };

  const paragraphStyle = {
    fontSize: '20px',
    color: '#000000',
    fontFamily: "EB Garamond, serif",
    fontWeight: '100',
    padding: '10px',
    margin: '10px auto',
    lineHeight: '1.6',
  };

  const linkStyle = {
    textDecoration: 'none',
    fontFamily: "EB Garamond, serif",

    fontWeight: '100',
    color: '#745B4F',
  };

  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '10px',
    margin: '0 2rem',
  };

  const imageStyle = {
    display: 'block',
    margin: '1rem auto',
    borderRadius: '0px',
    height: 'auto',
  };

  const pinterestGridStyle = {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(auto-fit, minmax(236px, 1fr))',
    justifyItems: 'center',
    margin: '1rem 0',
  };

  const captionStyle = {
    textAlign: 'center',
    fontSize: '1rem',
    fontFamily: 'Arapey, serif',
    fontWeight: '100',
    margin: '0 auto',
    fontStyle: 'italic',
  };



return (
    <div ref={blogRef}>
      <Helmet>
  <title>Dallas Cowboys Cheerleader Makeup Tutorial - Achieve the Iconic Glam Look!</title>
  <link rel="canonical" href="https://www.jackiewyers.beauty/articles/dcc-cheerleader" />

  <meta 
    name="description" 
    content="Follow Jackie Wyers' Dallas Cowboys Cheerleader makeup tutorial for an iconic glam look. High-end products and budget-friendly dupes for a stunning transformation." 
  />
  <meta 
    name="keywords" 
    content="Dallas Cowboys Cheerleader makeup tutorial, Jackie Wyers, cheerleader glam makeup, smokey eyes tutorial, beauty tips, glam makeup look, affordable makeup dupes, high-end makeup, Dallas Cowboys, DCC makeup, Southern bombshell beauty, Madeline Cope inspired makeup, cheer squad makeup, makeup tutorial, pop culture beauty trends, Jackie Wyers beauty blog" 
  />

  {/* Open Graph Meta Tags */}
  <meta property="og:title" content="Dallas Cowboys Cheerleader Makeup Tutorial - Achieve the Iconic Glam Look!" />
  <meta 
    property="og:description" 
    content="Follow Jackie Wyers' Dallas Cowboys Cheerleader makeup tutorial for an iconic glam look. High-end products and budget-friendly dupes for a stunning transformation." 
  />
  <meta 
    property="og:image" 
    content="https://www.jackiewyers.beauty/Images/Articles/DCC/DCCMakeupThumb.jpg" 
  />
  <meta property="og:url" content="https://www.jackiewyers.beauty/articles/dcc-cheerleader" />
  <meta property="og:type" content="article" />

  {/* Twitter Meta Tags */}
  <meta name="twitter:title" content="Dallas Cowboys Cheerleader Makeup Tutorial - Achieve the Iconic Glam Look!" />
  <meta 
    name="twitter:description" 
    content="Follow Jackie Wyers' Dallas Cowboys Cheerleader makeup tutorial for an iconic glam look. High-end products and budget-friendly dupes for a stunning transformation." 
  />
  <meta 
    name="twitter:image" 
    content="https://www.jackiewyers.beauty/Images/Articles/DCC/DCCMakeupThumb.jpg" 
  />
  <meta name="twitter:card" content="summary_large_image" />

  {/* JSON-LD Structured Data */}
  <script type="application/ld+json">
    {`
      {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": "Dallas Cowboys Cheerleader Makeup Tutorial - Achieve the Iconic Glam Look!",
        "description": "Follow Jackie Wyers' Dallas Cowboys Cheerleader makeup tutorial for an iconic glam look. High-end products and budget-friendly dupes for a stunning transformation.",
        "image": "https://www.jackiewyers.beauty/Images/Articles/DCC/DCCMakeupThumb.jpg",
        "author": {
          "@type": "Person",
          "name": "Jackie Wyers"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Jackie Wyers Beauty",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.jackiewyers.beauty/logo.png"
          }
        },
        "datePublished": "2024-11-15",
        "dateModified": "2024-11-15",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.jackiewyers.beauty/articles/dcc-cheerleader"
        }
      }
    `}
  </script>
</Helmet>
      <Card
        title="Dallas Cowboys Cheerleader Makeup Tutorial"
        description="Learn how to recreate the Dallas Cowboys Cheerleader-inspired makeup look with step-by-step guidance and product recommendations, both high-end and budget-friendly!"
        blogContent={
          <div className="container">
        
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
        <a href="/popculture" style={{ fontFamily: 'Arapey, sans-serif', fontSize: '1rem', color: '#000000', textDecoration: 'none' }}>
          BEAUTY // POP CULTURE
        </a>
      </div>
  
            <ArticleTitle
              mainTitle="Dallas Cowboys Cheerleader Makeup Tutorial"
              subTitle="Get the DCC look, without the cheer skills!"
              author="Jackie Wyers"
              publishDate="November 15th, 2024"
            />
  
            <SocialShare
              url="https://www.jackiewyers.beauty/articles/dcc-cheerleader"
              title="Dallas Cowboys Cheerleader Makeup Tutorial"
              imageUrl="https://www.jackiewyers.beauty/Images/DCC/DCCMakeupThumb.jpg"
            />
             

      


<ResponsiveYoutube src="https://www.youtube.com/embed/rPUWVdI2UEs?si=x4uAQ1iW8AcA0cKi" title="Dallas Cowboys Cheerleader Makeup Tutorial" />

     
    

<p style={{ margin: '20px auto', textAlign: 'center', fontSize: '1.1rem', fontFamily: 'Playfair Display, serif', fontStyle: 'italic' }}>
All products featured are chosen by Jackie Wyers. We may earn commission on some of the items you choose to buy.
</p>
  
            <div style={{ textAlign: "center", margin: "20px auto" }}>
              <SubscribeButton />
            </div>
  
           <DropCap1
           text="Get ready to transform into a Southern bombshell with this Dallas Cowboys Cheerleader-inspired makeup tutorial! I adore pop culture, and loved the Netflix docu-series America's Sweethearts: Dallas Cowboys Cheerleaders and with the news of the show coming back for season 2, I thought we could all celebrate together by trying out an inspired makeup look. Referencing makeup artist Madeline Cope, who has worked with DCC squad members on iconic glam looks for their cameo shoots, this guide will help you recreate a similar, ultra-glam aesthetic with both high-end and affordable favorites. Follow along to see how I achieved this look and even find drugstore dupes to recreate the glam without the splurge!
              "/>
  
  <div style={{ position: 'relative', display: 'inline-block' }}>
  <img 
    src="https://res.cloudinary.com/dzhlitors/image/upload/v1731684766/image1_bbjcl2.jpg" 
    alt="Dallas Cowboys Cheerleader Makeup - Jackie Wyers" 
    style={imageStyle} 
    data-pin-url="/articles/dcc-cheerleader"
    data-pin-media="https://res.cloudinary.com/dzhlitors/image/upload/v1731684766/image1_bbjcl2.jpg"
    data-pin-description="Dallas Cowboys Cheerleader Makeup - Jackie Wyers"
  />
  <img 
    src={pinterest}
    alt="Pin this on Pinterest" 
    style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      width: '50px',
      height: '50px',
      cursor: 'pointer',
      opacity: 0.9,
      transition: 'opacity 0.3s'
    }}
    onClick={() => window.open('https://pinterest.com/pin/create/button/?url=https://www.jackiewyers.beauty/articles/dcc-cheerleader&media=https://res.cloudinary.com/dzhlitors/image/upload/v1731684766/image1_bbjcl2.jpg&description=Jackie Wyers Wicked makeup inspiration.')}
    onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
    onMouseLeave={(e) => e.currentTarget.style.opacity = 0.7}
  />
</div>
            
  
  
            <h2 style={headingStyle}>DCC Beauty Breakdown</h2>
            <p style={paragraphStyle}>
            First step for the Dallas Cowboys Cheerleader makeover - the hair! I noticed they dim down bright red hair, darken brown hair to be richer, and brighten blonde or dirty blonde hair. I chose to go dark similar to my favorite cheerleader, Kelcey, and the makeup is matte, contoured with rosy lips and bold, smokey eyes.
            </p>
  
            <h2 style={headingStyle}>The Perfect Base</h2>

<p style={paragraphStyle}>
  Start with a hydrated face. And if you’re outdoors, SPF is a must! I used Charlotte Tilbury Magic Cream, a moisturizing favorite that I received in PR, to set the stage beautifully. If you’re looking for a more budget-friendly option, try Revolution Miracle Cream for similar results. Prep is essential!
</p>

<ResponsiveIframe 
  title="Hydrating Creams" 
  src="https://shopmy.us/collections/public/993006?noHeader=true" 
  />


<p style={paragraphStyle}>
  <strong>Foundation:</strong> The Giorgio Armani Luminous Silk Foundation offers a flawless base that’s both lightweight and radiant—perfect for cheer-ready skin. For a more affordable alternative, consider L’Oreal True Match Lumi.
</p>

<ResponsiveIframe 
  title="Foundation Options" 
  src="https://shopmy.us/collections/public/993018?noHeader=true" 
  />


<p style={paragraphStyle}>
  <strong>Concealer:</strong> Brighten up the under-eyes with Charlotte Tilbury Beautiful Skin Radiant Concealer to lift and illuminate the center of your face. For a similar effect at a lower price, Elf Camo Concealer works wonders.
</p>

<ResponsiveIframe 
  title="Concealer Options" 
  src="https://shopmy.us/collections/public/993021?noHeader=true" 
/>

<h2 style={headingStyle}>Sculpted and Sun-Kissed Contour</h2>

<p style={paragraphStyle}>
  For a defined, sculpted look, I used the Makeup by Mario Contour Stick. A great budget option is the Sheglam Snatch & Contour Stick. To blend seamlessly, I spritzed d’Alba Piedmont Spray Serum onto my brush, a blending technique that ensures a smooth, sun-kissed glow.
</p>

<ResponsiveIframe 
  title="Contour Products" 
  src="https://shopmy.us/collections/public/993041?noHeader=true" 
  
/>

<h2 style={headingStyle}>Rosy Cheeks & Luminous Blush</h2>

<p style={paragraphStyle}>
  For a touch of pink, I went with the Patrick Ta x Barbie collab, which includes a rosy blush and lip gloss. Love Barbie collaborations as much as I do? See my article on the best Barbie collabs of 2024 <a href="/articles/best-barbie-collabs-2024" style={linkStyle}>here</a>. I SO want a DCC Barbie!
</p>

<p style={paragraphStyle}>
  Anyways, for a budget-friendly blush, e.l.f Putty Blush is a great alternative to bring that same DCC vibe.
</p>

<ResponsiveIframe 
  title="Blush Products" 
  src="https://shopmy.us/collections/public/993050?noHeader=true" 

/>


<h2 style={headingStyle}>Set with Powder</h2>

<p style={paragraphStyle}>
  To keep everything in place, I used One/Size Powder by Patrick Starrr in a peachy shade. e.l.f Halo Glow Setting Powder in Peach or Pink provides a similar effect, offering a soft, matte finish without the high price tag.
</p>

<ResponsiveIframe 
  title="Setting Powder Products" 
  src="https://shopmy.us/collections/public/993057?noHeader=true" 
  
/>

<p style={paragraphStyle}>
  To set the look, Charlotte Tilbury Airbrush Flawless Setting Spray is ideal, but L’Oreal Paris Setting sprays are a fantastic, affordable option.
</p>

<ResponsiveIframe 
  title="Setting Spray Products" 
  src="https://shopmy.us/collections/public/993059?noHeader=true" 
  
/>

<h2 style={headingStyle}>Brows That Pop</h2>

<p style={paragraphStyle}>
  The DCC squad’s brow game is always strong. I aimed for a full yet natural brow with Benefit Precisely, My Brow Pencil. If you’re looking for a similar look on a budget, try L’Oreal Brow Stylist Definer.
</p>

<ResponsiveIframe 
  title="Brow Products" 
  src="https://shopmy.us/collections/public/993061?noHeader=true" 
  
/>


<h2 style={headingStyle}>The Classic Smokey Eye</h2>

<p style={paragraphStyle}>
  For the eyes, I used the Makeup by Mario Master Mattes Palette to create a soft crease and smoky effect. The Colourpop Gone Matte Palette is a great alternative with a similar shade range.
</p>

<ResponsiveIframe 
  title="Smokey Eye Palettes" 
  src="https://shopmy.us/collections/public/993062?noHeader=true" 
  
/>

<p style={paragraphStyle}>
  For a pop of brightness on the lid, I used Laura Mercier’s Caviar Stick in Champagne, which can be swapped with Revlon Colourstay Creme Eye Shadow for a more affordable option.
</p>

<ResponsiveIframe 
  title="Brightening Eye Shadows" 
  src="https://shopmy.us/collections/public/993068?noHeader=true" 
  
/>

<p style={paragraphStyle}>
  Finish the eye look with Makeup Forever’s Artist Color Pencils. Start with Whatever Black for a soft winged-out liner shape, and Dimensional Dark Brown in the waterline, smoked out from the bottom lashline. A drugstore option I always recommend is the Revlon So Fierce Vinyl Eyeliner in Midnight Mystery and Mighty Mocha. Super creamy and rich in pigment!
</p>

<ResponsiveIframe 
  title="Eyeliner Options" 
  src="https://shopmy.us/collections/public/993069?noHeader=true" 
  
/>

<h2 style={headingStyle}>Lashes & Lips</h2>

<p style={paragraphStyle}>
  For fluttery lashes, try Ardell Naked Lashes 421 like Madeline used on Reece. For that bold lip, Makeup Forever’s Wherever Walnut Lip Liner paired with Charlotte Tilbury Matte Revolution Lipstick in Wedding Belles gives a beautifully defined pout.
</p>

<ResponsiveIframe 
  title="Lashes and High-End Lip Products" 
  src="https://shopmy.us/collections/public/993073?noHeader=true" 
  
/>

<p style={paragraphStyle}>
  Budget-friendly swaps are Maybelline Lifter Lipliner and Colourpop Lipstick in Brink. Finish with Maybelline Lifter Gloss for added shine and hydration in the shade Gummy Bear.
</p>

<ResponsiveIframe 
  title="Budget-Friendly Lip Products" 
  src="https://shopmy.us/collections/public/993079?noHeader=true" 
  
/>

{/* Embed dance video */}
<div style={{ marginBottom: '30px', marginTop: '20px', textAlign: 'center' }}>
  <iframe
    style={{ boxShadow: '12px', borderRadius: '15px', width: '301px', height: '535px' }}
    src="https://www.youtube.com/embed/lxCeaLYYT74"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>


<p style={paragraphStyle}>
  For a comprehensive look at the products used by the Dallas Cowboys Cheerleaders, you can refer to this 
  <a href="https://www.dailymail.co.uk" target="_blank" rel="noopener noreferrer" style={linkStyle}>Daily Mail article</a>!
</p>

<p style={paragraphStyle}>
  I used many of the exact products with some of my own personal favorites to make the look suit me best. Can’t get enough of the DCC? 
  Here's an <a href="https://www.youtube.com/embed/6Gk7bv-6ucY?si=d1OJ5ZkUt-TXDxxH" target="_blank" rel="noopener noreferrer" style={linkStyle}>official Dallas Cowboys Cheerleaders makeup tutorial</a> by bubbly Kat to guide you through the process as well.
</p>

<h2 style={headingStyle}>Makeup Tutorial From Dallas Cowboys Cheerleader</h2>

<p style={paragraphStyle}>
  I wore this Dallas Cowboys Cheerleader costume for Halloween—here’s the final look and my attempt at the iconic Thunderstruck routine! 
  15 seconds that is…😂
</p>

<div style={{ position: 'relative', display: 'inline-block' }}>
  <img 
    src="https://res.cloudinary.com/dzhlitors/image/upload/v1731684766/image1_bbjcl2.jpg" 
    alt="Dallas Cowboys Cheerleader Makeup - Jackie Wyers" 
    style={imageStyle} 
    data-pin-url="/articles/dcc-cheerleader"
    data-pin-media="https://res.cloudinary.com/dzhlitors/image/upload/v1731684766/image1_bbjcl2.jpg"
    data-pin-description="Dallas Cowboys Cheerleader Makeup - Jackie Wyers"
  />
  <img 
    src={pinterest}
    alt="Pin this on Pinterest" 
    style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      width: '50px',
      height: '50px',
      cursor: 'pointer',
      opacity: 0.9,
      transition: 'opacity 0.3s'
    }}
    onClick={() => window.open('https://pinterest.com/pin/create/button/?url=https://www.jackiewyers.beauty/articles/dcc-cheerleader&media=https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image4_houb0k.jpg&description=Jackie Wyers Wicked makeup inspiration.')}
    onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
    onMouseLeave={(e) => e.currentTarget.style.opacity = 0.7}
  />
</div>

<div style={{ position: 'relative', display: 'inline-block' }}>
  <img 
    src="https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image4_houb0k.jpg" 
    alt="Dallas Cowboys Cheerleader Makeup - Jackie Wyers" 
    style={imageStyle} 
    data-pin-url="/articles/dcc-cheerleader"
    data-pin-media="https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image4_houb0k.jpg"
    data-pin-description="Dallas Cowboys Cheerleader Makeup - Jackie Wyers"
  />
  <img 
    src={pinterest}
    alt="Pin this on Pinterest" 
    style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      width: '50px',
      height: '50px',
      cursor: 'pointer',
      opacity: 0.9,
      transition: 'opacity 0.3s'
    }}
    onClick={() => window.open('https://pinterest.com/pin/create/button/?url=https://www.jackiewyers.beauty/articles/dcc-cheerleader&media=https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image4_houb0k.jpg&description=Jackie Wyers Wicked makeup inspiration.')}
    onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
    onMouseLeave={(e) => e.currentTarget.style.opacity = 0.7}
  />
</div>

<div style={{ position: 'relative', display: 'inline-block' }}>
  <img 
    src="https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image2_rxpfdz.jpg" 
    alt="Dallas Cowboys Cheerleader Makeup - Jackie Wyers" 
    style={imageStyle} 
    data-pin-url="/articles/dcc-cheerleader"
    data-pin-media="https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image2_rxpfdz.jpg"
    data-pin-description="Dallas Cowboys Cheerleader Makeup - Jackie Wyers"
  />
  <img 
    src={pinterest}
    alt="Pin this on Pinterest" 
    style={{
      position: 'absolute',
      top: '20px',
      right: '20px',
      width: '50px',
      height: '50px',
      cursor: 'pointer',
      opacity: 0.9,
      transition: 'opacity 0.3s'
    }}
    onClick={() => window.open('https://pinterest.com/pin/create/button/?url=https://www.jackiewyers.beauty/articles/dcc-cheerleader&media=https://res.cloudinary.com/dzhlitors/image/upload/v1731684767/image2_rxpfdz.jpg&description=Jackie Wyers Wicked makeup inspiration.')}
    onMouseEnter={(e) => e.currentTarget.style.opacity = 1}
    onMouseLeave={(e) => e.currentTarget.style.opacity = 0.7}
  />
</div>


{/* Embed dance video */}
<div style={{ marginBottom: '30px', marginTop: '20px', textAlign: 'center' }}>
  <iframe
    style={{ boxShadow: '12px', borderRadius: '15px', width: '301px', height: '535px' }}
    src="https://www.youtube.com/embed/HYp5KvAVEyY"
    title="YouTube video player"
    frameBorder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  ></iframe>
</div>



<p style={paragraphStyle}>
  If you love all things beauty and pop culture, subscribe to the newsletter to be updated weekly on new articles uploaded to the site.
  Comment below with your favorite cheerleader from the series, and if you try out this look, tag me—I’d love to see it! 
  Want more glamorous inspiration similar to DCC glam? Check out the VSFS-inspired look below.
</p>



  
            <NextArticle
              link="/vsfs-inspired-look"
              imgSrc="https://www.jackiewyers.beauty/Images/Articles/VictoriaSecret2014/VSThumbnail.jpeg"
              altText="Victoria's Secret Fashion Show Inspired Look"
              linkText="EXPLORE VSFS LOOKS →"
              containerStyle={{ margin: "2rem auto" }}
              linkStyle={{ color: "#000000", fontSize: "1.25rem" }}
              imgStyle={{ borderRadius: "10px" }}
            />
  
            <div style={{ textAlign: "center", margin: "2rem 0" }}>
              <img
                src="https://www.jackiewyers.beauty/Images/Articles/Signature.png"
                alt="Jackie Wyers Signature"
                style={{ width: "100%", maxWidth: "650px" }}
              />
            </div>
  
            <Comments website-id="10910" page-id="dcc-makeup-tutorial" />
          </div>
        }
      />
    </div>
  );
}

export default DCCMakeupTutorial;